import React from "react";
import {
  FaUsers,
  FaFlag,
  FaGlobeEurope,
  FaLocationArrow,
  FaClock,
  FaArrowCircleLeft,
  FaSpinner,
} from "react-icons/fa";
import { graphql, Link } from "gatsby";
import { de } from "date-fns/locale";
import format from "date-fns/format";
import { useQuery } from "react-query";
import Layout from "../../components/layout";

export default function Event({ params, data: { config } }) {
  const query = useQuery(["event", params.id], async () => {
    return fetch(`/api/event/${params.id}?event_id=${params.id}`).then((res) =>
      res.json()
    );
  });
  console.log(config);
  const event = query?.data;
  return (
    <div
      className="bg-center bg-no-repeat bg-cover"
      style={{
        backgroundAttachment: "fixed",
        backgroundImage: `url(${event?.cover?.source})`,
      }}
    >
      <div className="bg-white/80 dark:bg-dark/80">
        <Layout transparent>
          {query.isLoading && (
            <div className="container py-10 text-center lg:py-20">
              <FaSpinner className="mx-auto text-2xl animate-spin" />
            </div>
          )}
          {query?.data && (
            <div>
              <div className="container py-10 lg:py-20">
                <div className="grid max-w-3xl grid-cols-1 mx-auto">
                  <div className="flex items-center justify-between mb-8">
                    <Link to="/" className="inline-flex items-center space-x-2">
                      <FaArrowCircleLeft />
                      <span>Zurück </span>
                    </Link>

                    <div className="">
                      <a
                        href={config?.data?.aleno_link}
                        target="_blank"
                        className="inline-block px-5 py-2 text-lg font-semibold uppercase phone:hidden bg-blue-light text-blue-dark "
                      >
                        Lounge reservieren
                      </a>
                    </div>
                  </div>
                  <div className=" bg-white/90 dark:bg-black/90 ring-8 ring-black/20 dark:ring-white/20">
                    <img src={event?.cover?.source} className="w-full mb-4" />
                    <div className="p-4 prose dark:prose-dark max-w-none">
                      <h1>{event.name}</h1>
                      <div className="">
                        <a
                          href={config?.data?.aleno_link}
                          target="_blank"
                          className="inline-block mb-3 px-4 py-1.5 text-base   uppercase md:hidden bg-blue-light !font-semibold !no-underline !text-blue-dark "
                        >
                          Lounge reservieren
                        </a>
                      </div>

                      <Details Icon={FaUsers}>
                        <span>
                          Zugesagt:{" "}
                          <strong>{event.attending_count ?? 0}</strong>
                        </span>{" "}
                        <span>/</span>
                        <span>
                          Interessiert: <strong> {event.maybe_count}</strong>
                        </span>{" "}
                        <span>/</span>
                        <span>
                          Abgesagt: <strong> {event.declined_count}</strong>
                        </span>{" "}
                        <span>/</span>
                        <span>
                          Eingeladen: <strong>{event.noreply_count}</strong>
                        </span>
                      </Details>
                      <Details Icon={FaFlag}>
                        Veranstaltung von <strong>{event?.owner?.name}</strong>
                      </Details>
                      <Details Icon={FaLocationArrow}>
                        <strong>{event?.place?.name}</strong>
                      </Details>
                      <Details Icon={FaClock}>
                        <span>
                          {event?.start_time &&
                            format(
                              new Date(event?.start_time),
                              "EEEE, LLLL dd, yyyy",
                              { locale: de }
                            )}
                          {event?.start_time && " at "}
                          {event?.start_time &&
                            format(new Date(event?.start_time), "p O", {
                              locale: de,
                            })}{" "}
                          {event?.end_time && "-"}{" "}
                          {event?.end_time &&
                            format(new Date(event?.end_time), "p O", {
                              locale: de,
                            })}
                        </span>{" "}
                      </Details>
                      <Details Icon={FaGlobeEurope}>
                        <span className="capitalize">
                          {event?.type == "public" ? "Öffentlich" : event?.type}
                        </span>
                      </Details>

                      <h3>Details:</h3>
                      <p
                        className="overflow-hidden whitespace-pre-line"
                        dangerouslySetInnerHTML={{
                          __html: registerLinks(event?.description) ?? "",
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Layout>
      </div>
    </div>
  );
}

function Details({ Icon, children }) {
  return (
    <p className="grid gap-4  grid-cols-[auto,1fr]">
      <Icon className="text-2xl text-dark dark:text-white "> </Icon>
      <div className="space-x-2 ">{children}</div>
    </p>
  );
}

function registerLinks(text) {
  return text
    ? text.replace(
        /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim,
        `<a href="$&" target="_blank">$&</a>`
      )
    : text;
}
export const query = graphql`
  query {
    config: prismicConfig {
      data {
        aleno_link
      }
    }
  }
`;
